.mobileNav{
	background-color:#753ffd;
	width: 100%;
	height:40px;
	display: none;
}

.mobileNavBtn{
	color: #130202;
	background: transparent;
	outline: none;
	border: none;
	margin: 0 10px;
}

@media only screen and (min-width: 768px){
	.sidenav{
		width: 40%;
		padding: 10px;
	}
    .mobileNavBtn{
		display: none;
	}
}

@media only screen and (max-width: 600px){
	.mobileNav{
		display: flex;
		align-items: center;
	}
	.sidenav{
		width: 70%;
		top: 38px;
		min-height: calc(100vh - 38px);
	}

	.menuBtn{
		display: none;
	}

    .sidenavClosed {
        composes: sidenav;
        transition: width 0.3s ease-in-out;
        width: 0px
    }
}

.sidenav {
    width: 100vh;
    max-width: 300px;
    transition: width 0.3s ease-in-out;
    height: calc(100vh - 38px);
    background-color: rgb(10,25,41);
    padding-top: 28px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.sidenavClosed {
    composes: sidenav;
    transition: width 0.3s ease-in-out;
    width: 80px
}
.sideitem {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
    color: #B2BAC2;
    text-decoration: none;
    overflow: hidden;
}
.linkText {
    padding-left: 16px;
}

.sideitem:hover {
    background-color: #244f7d1c;
}
.menuBtn {
    align-self: center;
    align-self: flex-start;  
    justify-self: flex-end;
    color: #B2BAC2;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding-left: 20px;
    font-size: 0;
}
.menuBtnClosed {
    font-size: 0 !important;
}

.menuBtnOpen {
    font-size: 18px !important;
}