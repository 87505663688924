body {
	margin: 0;
	padding: 0;
}

.App {
	display: flex;
}
	
main {
	padding: 10px;
}